import Flag from 'react-world-flags'
function linkify(text){
    var urlRegex =/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>'
    });
}
const Message = ({name, rank, level, avatar, message, hide_country, hide_level, country}) => {
    const rankToName = () => {
        switch (rank) {
            case 1:
                return 'Trusted User ✔'
            case 2:
                return 'Drunk User 🍺'
            case 3:
                return 'Mod 😊💎'
            case 4:
                return 'Admin'
            case 5:
                return 'Owner 💲🏀💎'
            case 6:
                return 'Bot 🤖'
            case 7:
                return 'Developer 🤓'
            case 8:
                return 'Administrator 🐈'
        }
    }

    const rankToColor = () => {
        switch (rank) {
            case 2:
                return '#41b700'
            case 3:
                return '#3b97ff'
            case 4:
                return '#ff4648'
            case 5:
                return '#BED859'
            case 6:
                return '#ff4648'
            case 7:
                return '#ff4648'
            case 8:
                return '#FF00FF'
            default: 
                return null
        }
    }
/*
    if (Message.rank > 2){
        Message.message = linkify(Message.message)
    }
*/
    return (
        <>
            <div className='message-container'>                
                <div className='message-info'>
                    <div className='user-info'>
                        <img className='avatar' src={avatar} alt=''/>
                        <p className={'level ' + (hide_level && 'hidden')}>{level || 0}</p>
                        <p className='name' translate='no'>{name}</p>
                        <p className={'country ' + (!hide_country && 'active')}> <Flag code={country} height="14"/></p>
                        <p className={'rank ' + rankToName()?.toLocaleLowerCase()}>{rankToName()}</p>
                    </div>
                </div>

                <div className='message'>
                        <p>{message}</p>
                </div>
            </div>

            <style jsx>
                {`
                    .message-container {
                        width: 100%;
                        height: fit-content;

                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        background-color: #22294D;
                        padding: 10px;
                        border-radius: 6px;

                        margin-top: 15px;
                    }

                    .avatar {
                        width: 25px;
                        height: 25px;
                        border-radius: 4px;
                    }

                    .message-info {
                        width: 100%;
                    }

                    .user-info {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }

                    .user-info p {
                        margin: unset;
                    }

                    .level {
                        box-sizing: content-box;
                        padding: 0px 8px;
                        background-color: #2B3D6D;
                        color: #629CF1;
                        font-size: 12px;
                        font-weight: 600;
                    }

                    .level.hidden {
                        display: none!important;
                    }

                    .name {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: white;
                        font-size: 12px;
                        font-weight: 600;
                    }

                    .rank {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: white;
                        font-size: 12px;
                        font-weight: 600;
                        padding: 2px 12px;
                        border-radius: 6px;
                    }

                    .owner {
                        background: linear-gradient(0deg, rgb(50 63 113) 0%, rgb(34 41 77) 35%, rgb(57 121 173) 100%);
                        color: #fff;
                    }

                    .admin {
                        background-color: #482A49; 
                        color: #F04D4F;
                    }

                    .mod {
                        background-color: #255558; 
                        color: #63D68C;
                    }

                    .developer {
                        background: #00c6ff;  /* fallback for old browsers */
                        background: -webkit-linear-gradient(to right, #0072ff, #00c6ff);  /* Chrome 10-25, Safari 5.1-6 */
                        background: linear-gradient(to right, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        
                        color: #fff;
                    }

                    .administrator {
                        background: rgb(243,244,32);
                        background: linear-gradient(0, #c71d09, #f7860d);

                        color: #FBE7D5;
                    }
                    .bot {
                        color: #fff;
                        background-color: #1845cc;
                        background: linear-gradient(180deg, #3498db, #0d3662);
                    }
                    .trusted {
                        color: #ffcc00;
                        background-color: #255558;
                        background: linear-gradient(180deg, #498f59, #134E5E);
                    }

                    .drunk {
                        color: #ffcc00;
                        background-color: #255558;
                        background: linear-gradient(180deg, #498f59, #134E5E);
                    }


                    .message {
                        font-weight: 600;
                        font-size: 13px;                        
                        color: #9CA4BF;
                        width: 100%;
                        
                        word-wrap: break-word;
                        white-space: pre-wrap;
                    }

                    .message p {
                        margin: 3px 0px 0px 0px;
                    }

                    .message a {
                        color: white; /* Set link text color to white */
                        text-decoration: underline; /* Add underline to the link */
                    }

                    .country {
                        display: none;
                    }

                    .country.active {
                        display: flex!important;
                    }
                `}
            </style>
        </>
    )
}

export default Message
