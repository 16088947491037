const Earning = ({avatar, username, title, amount}) => {
    return (
        <>
            <div className='earning-container'>
                <img className='avatar' src={avatar} alt=''/>

                <div>
                    <p className='wall'>{title}</p>
                    <p className='name' translate='no'>{username}</p>
                </div>

                <p className='earned'>{(typeof amount === 'number' ? amount.toFixed(2) : amount)}</p>
            </div>

            <style jsx>
                {`
                    .earning-container {
                        width: fit-content;
                        user-select: none;
                        background-color: #262E58;
                        border-radius: 4px;
                        height: 40px;
                        min-width: 150px;

                        display: flex;
                        align-items: center;
                        padding: 0px 6px;

                        gap: 10px;
                    }

                    .avatar {
                        height: 30px;
                        border-radius: 4px;
                    }

                    .wall {
                        color: #727a94;
                        font-weight: 500;
                        font-size: 10px;
                        margin: unset;
                    }

                    .name {
                        color: #9CA4BF;
                        font-weight: 500;
                        font-size: 13px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: unset;
                        max-width: 85px;
                    }

                    .earned {
                        color: #4F8DDE;
                        background-color: #1C2344;
                        padding: 0px 5px;
                        border-radius: 3px;
                        font-size: 17px;
                        font-weight: 600;
                        margin-left: 15px;
                    }
                `}
            </style>
        </>
    )
}

export default Earning