import { faGem, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'
import Placement from './monthlyplacement'
import MonthCountdown from './monthlycountdown'
const Monthly = () => {

    const [places, setPlaces] = useState([])

    useEffect(() => {
        async function getLB() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/leaderboard/month`, { credentials: 'include' });
                const data = await res.json();
            
                setPlaces(data)
            } catch (e) {
                return
            }
        }
        
        getLB()
    }, [])

    return (
        <>
        <div><MonthCountdown/></div>
            <div className='topthree'>
                <div className='first'>

                    <p className='prize'>Reward $50</p>

                    {places[0] ? (
                        <img src={places[0]?.avatar || '/icons/nouser.png'} alt='' draggable='false'/>
                    ) : (
                        <div className='noone'>
                            <FontAwesomeIcon icon={faQuestionCircle}/>
                        </div>
                    )}

                    <div className='podium'>
                        <div className='top'>
                            <p className='gold place'>1st</p>

                            <p className='username' translate='no'>{places[0]?.name || 'No one'}</p>
                        </div>

                        <p className='earned'>Earned <span>{places[0]?.earned?.toFixed(0) || 0} <FontAwesomeIcon icon={faGem}/></span> this month</p>
                    </div>
                </div>
                    
                <div className='second'>

                <p className='prize'>Reward $30</p>

                    {places[1] ? (
                        <img src={places[1]?.avatar || '/icons/nouser.png'} alt='' draggable='false'/>
                    ) : (
                        <div className='noone'>
                            <FontAwesomeIcon icon={faQuestionCircle}/>
                        </div>
                    )}
                    <div className='podium'>
                        <div className='top'>
                            <p className='gold place'>2nd</p>

                            <p className='username' translate='no'>{places[1]?.name || 'No one'}</p>
                        </div>

                        <p className='earned'>Earned <span>{places[1]?.earned?.toFixed(0) || 0} <FontAwesomeIcon icon={faGem}/></span> this month</p>
                    </div>
                </div>

                <div className='third'>

                <p className='prize'>Reward $15</p>

                    {places[2] ? (
                        <img src={places[2]?.avatar || '/icons/nouser.png'} alt='' draggable='false'/>
                    ) : (
                        <div className='noone'>
                            <FontAwesomeIcon icon={faQuestionCircle}/>
                        </div>
                    )}

                    <div className='podium'>
                        <div className='top'>
                            <p className='gold place'>3rd</p>

                            <p className='username' translate='no'>{places[2]?.name || 'No one'}</p>
                        </div>

                        <p className='earned'>Earned <span>{places[2]?.earned?.toFixed(0) || 0} <FontAwesomeIcon icon={faGem}/></span> this month</p>
                    </div>
                </div>
            </div>

            <div className='remaining'>
                {places.slice(3).map((place, index) => (
                    <Placement user={place} place={index + 4} reward={1}/>
                ))}
            </div>

            <style jsx>{`
                .topthree {
                    display: flex;
                    width: 100%;
                    gap: 15px;
                    
                    align-items: flex-end;
                }

                .first, .second, .third {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    gap: 15px;
                    min-width: 180px;
                }

                .first img, .second img, .third img {
                    width: 85px;
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
                    border-radius: 10px;
                    transition: all 0.5s ease-in-out;
                }

                .first img:hover, .second img:hover, .third img:hover {
                    transform: translate(0, -4px);
                }

                .noone {
                    width: 85px;
                    height: 85px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: #9CA4BF;
                    font-size: 2em;

                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
                    border-radius: 10px;
                    transition: all 0.5s ease-in-out;
                }

                .first {
                    order: 1;
                }

                .second {
                    order: 0;
                }

                .third {
                    order: 2;
                }

                .podium {
                    background-color: #141A31;
                    width: 100%;
                    border-radius: 12px;
                    padding: 15px; 

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .first .podium {
                    height: 160px;
                    min-height: 160px;
                }

                .second .podium {
                    height: 130px;
                    min-height: 130px;
                }

                .third .podium {
                    height: 100px;
                    min-height: 100px;
                }

                .prize {
                    border-radius: 9px;
                    font-size: 16px;
                    color: rgb(241, 196, 117);
                    background-color: rgb(28, 35, 68);
                    padding: 3px 15px;
                    box-shadow: rgb(0 0 0 / 20%) 0px 3px 10px;
                    user-select: none;
                }

                .top {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    position: relative;
                }

                .username {
                    text-align: center;
                    width: 100%;
                    color: #4F8DDE;
                    font-size: 24px;
                    font-weight: 600;
                }

                .place {
                    min-width: 40px;
                    min-height: 40px;
                    line-height: 40px;
                    background-color: #4D3A4B;
                    text-align: center;
                    color: #F1C475;
                    border-radius: 8px;

                    position: absolute;
                    top: 0px;
                    left: 0px;
                }

                .podium .earned {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .earned {
                    color: #4F8DDE;
                }

                .earned span {
                    color: #F1C475;
                }

                .remaining {
                    display: inline-grid;
                    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
                    grid-gap: 15px;
                }
                
                p {
                    margin: unset;
                }

                @media only screen and (max-width: 1500px) {
                    .podium {
                        flex-direction: column;
                        height: 200px !important;
                        text-align: center;
                    }

                    .place {
                        position: relative;
                    }
                }

                @media only screen and (max-width: 700px) {
                    .topthree {
                        flex-wrap: wrap;
                    }


                    .first {
                        order: 0;
                    }
    
                    .second {
                        order: 1;
                    }
                }
            `}</style>
        </>
    )
}

export default Monthly